
import { Component, Vue } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'

@Component({
  name: 'Asset',
  filters: {
    previewList: function (value: FileInfo[]) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class Asset extends Vue {
  private formInfo = {}

  get assetId () {
    return this.$route.params.assetId as string
  }

  created () {
    this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetByAssetId, {
      assetId: this.assetId
    }).then(res => {
      this.formInfo = res
    })
  }
}
